import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import { isBrowser } from 'utils/misc-utils';

const url = `/api/identify`;
const sessionKey = 'dutchie-session';

type State = string | null;

export class Session {
  private static instance?: Session;
  private _state: State = null;

  private constructor() {
    if (isBrowser()) {
      this._state = getPersistedValue(sessionKey);
    }
  }

  static getInstance(): Session {
    if (!Session.instance) {
      Session.instance = new Session();
    }

    return Session.instance;
  }

  get state(): State {
    return this._state;
  }

  set state(newState: State) {
    if (newState === this._state) {
      return;
    }

    setPersistedValue(sessionKey, newState);
    this._state = newState;
  }

  async identify(): Promise<void> {
    try {
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ session: this.state }),
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
        },
      });

      if (!resp.ok) {
        throw new Error(`request to identify session failed with status: ${resp.status}`);
      }

      if (resp.status === 200) {
        return;
      }

      if (resp.status === 201) {
        this.state = await resp.text();
      }
    } catch (err) {
      console.error(err);
    }
  }

  destroy(): void {
    this.state = null;
  }

  async reset(): Promise<void> {
    this.destroy();
    return this.identify();
  }
}
