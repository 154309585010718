/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';

import { isBrowser } from 'utils/misc-utils';

import { Session } from './session';

const session = Session.getInstance();

export function useInitSession(): void {
  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    void session.identify();
  }, []);
}
